import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {MapPoint} from '../interface/map';
import {HttpClient} from '@angular/common/http';
import {Memorial} from '../interface/memorial';
import {Page} from '../interface/page';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  API_URL = 'https://kiosk.3dcafe.ru/';
  constructor(private http: HttpClient) { }
  getMapPoints(): Observable<MapPoint[]> {
    return this.http.get<MapPoint[]>(`${this.API_URL}api/mapPoints`);
  }
  getMemorials(): Observable<Memorial[]> {
    return this.http.get<Memorial[]>(`${this.API_URL}api/memorials`);
  }
  getMemorialById(id): Observable<Memorial> {
    return this.http.get<Memorial>(`${this.API_URL}api/memorials/${id}`).pipe(
      map((e) => e[0])
    );
  }
  getPages(): Observable<Page[]> {
    return this.http.get<Page[]>(`${this.API_URL}api/pages`);
  }
  getPageByType(type): Observable<Page> {
    return this.http.get<Page[]>(`${this.API_URL}api/pages`).pipe(
      map(e => e.filter(e2 => e2.title === type).length > 0 ? e.filter(e2 => e2.title === type)[0] : null)
    );
  }
  getPageById(id): Observable<Page> {
    return this.http.get<Page>(`${this.API_URL}api/pages`);
  }
}
