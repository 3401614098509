import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../shared/services/theme.service';
import {ApiService} from "../shared/services/api.service";
import {Page} from "../shared/interface/page";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  constructor(public theme: ThemeService, private api: ApiService) { }
  page: Page;
  ngOnInit(): void {
    this.api.getPageByType('contact').subscribe((e) => {
      this.page = e;
    });
  }

}
