<div class="w-100 h-100 d-flex align-items-start flex-column"
     [class.light-bg]="theme.currentTheme === 'light'"
     [class.dark-bg]="theme.currentTheme === 'dark'"
>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div
      [class.light-bg-line]="theme.currentTheme === 'light'"
      [class.dark-bg-line]="theme.currentTheme === 'dark'"
      class="mar-x" style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      Карта-схема
    </span>
  </div>
  <div style="width: 100%; margin-top: 1vh; display: flex; justify-content: center; align-items: center">
    <div
      [class.light-bg-line]="theme.currentTheme === 'light'"
      [class.dark-bg-line]="theme.currentTheme === 'dark'"
      class="mar-x" style="width: 100%;height: 10px"></div>
  </div>
  <div style="width: 100vw; height: 100vh">
    <div class="mar-x" style="height: 89%;width: 80%;">
      <mgl-map
        [style]="theme.currentTheme === 'dark' ?
        'mapbox://styles/mafahes/ckm3jgyfoc71j17qks6a9uw5b'
       :
       'mapbox://styles/mafahes/ckmdd426a0bet17pfi0qjl2yg'
"
        [zoom]="[20]"
        [center]="[-74.5, 40]"
      >

      </mgl-map>
    </div>
  </div>
</div>
