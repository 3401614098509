import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {Howl, Howler} from 'howler';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {
  switchMenu: Subject<boolean> = new Subject<boolean>();
  constructor() { }
  playMchs(): void {
    const audio = new Audio();
    audio.src = './assets/sounds/mchsSound.mp3';
    audio.load();
    audio.play();
    // const sound = new Howl({
    //   src: ['assets/sounds/mchsSound.mp3']
    // });
    // sound.play();
  }
}
