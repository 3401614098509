import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../shared/services/theme.service';
import {ApiService} from "../shared/services/api.service";
import {Memorial} from "../shared/interface/memorial";

@Component({
  selector: 'app-memorial-list',
  templateUrl: './memorial-list.component.html',
  styleUrls: ['./memorial-list.component.scss']
})
export class MemorialListComponent implements OnInit {
  data = [
    {
      title: 'Владимир Семёнович Высоцкий',
      date: '25 января 1938, Москва - 25 июля 1980',
      routerLink: '/current-memorial'
    },
    {
      title: 'Владимир Семёнович Высоцкий',
      date: '25 января 1938, Москва - 25 июля 1980',
      routerLink: '/current-memorial'
    },
    {
      title: 'Владимир Семёнович Высоцкий',
      date: '25 января 1938, Москва - 25 июля 1980',
      routerLink: '/current-memorial'
    }
  ];
  memorials: Memorial[] = [];
  constructor(public theme: ThemeService, private api: ApiService) { }
  ngOnInit(): void {
    this.api.getMemorials().subscribe((e) => {
      this.memorials = e;
    });
  }

}
