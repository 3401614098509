<div class="w-100 h-100 d-flex align-items-start flex-column"
     [class.light-bg]="theme.currentTheme === 'light'"
     [class.dark-bg]="theme.currentTheme === 'dark'"
>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      Описание захоронения
    </span>
  </div>
  <div style="width: 100%; margin-top: 1vh; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 10px"></div>
  </div>
  <div class="mar-x" style="padding-top: 1vh" [innerHTML]="page?.text">
  </div>
</div>
