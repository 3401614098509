import {Component, OnInit} from '@angular/core';
import {FunctionsService} from "./services/functions.service";
import {ThemeService} from './shared/services/theme.service';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'media-kioski';
  opened = false;
  search = true;
  socket = new WebSocket('wss://kiosk.3dcafe.ru/ws/webSockets/Connect');
  constructor(private func: FunctionsService, public theme: ThemeService, private snackBar: MatSnackBar) {
  }
  openSearch(): void {
    this.search = !this.search;
  }
  ngOnInit(): void {
    this.socket.onopen = () => {
      this.socket.send(JSON.stringify({ method: 'RegisterDevice', data: '2' }));
      this.socket.onmessage = (e) => {
        if (e.data.includes('mchs') || e.data.includes('innerService')) {
          if (e.data.includes('mchs')) {
            console.log('hello');
            this.func.playMchs();
          }
        }
      };
    };
    this.func.switchMenu.subscribe((e) => {
      this.opened = true;
      setTimeout(() => this.opened = !this.opened, 100);
    });
  }
}
