<div class="w-100 d-flex align-items-start flex-column" style="min-height: 100%"
     [class.light-bg]="theme.currentTheme === 'light'"
     [class.dark-bg]="theme.currentTheme === 'dark'">
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      {{memorial?.name}}
    </span>
  </div>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div
      [class.light-bg-line]="theme.currentTheme === 'light'"
      [class.dark-bg-line]="theme.currentTheme === 'dark'"
      class="mar-x" style="width: 100%;height: 10px"></div>
  </div>
  <div class="my-4">

  </div>
  <div class="mar-x d-flex flex-column" style="height: 100%; width: calc(100% - 20vw)">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistRoman, sans-serif;font-size: 64px;">
      <b>{{memorial?.name}}</b> ({{memorial?.date | date: 'dd MMMM yyyy'}} - {{memorial?.date2 | date: 'dd MMMM yyyy'}})
    </span>
    <div class="my-3">

    </div>
    <div class="innerBox" [innerHTML]="memorial?.text" style="font-family: HumanistRoman, sans-serif;font-size: 64px;"></div>
<!--    <span-->
<!--      [class.light-text]="theme.currentTheme === 'light'"-->
<!--      [class.dark-text]="theme.currentTheme === 'dark'"-->
<!--      style="font-family: HumanistRoman, sans-serif;font-size: 64px;" [innerHTML]="memorial?.text">-->
<!--    </span>-->
    <div class="my-3">

    </div>
    <img style="border-radius: 43px;" [src]="memorial.photo.fullUrl"/>
    <div class="my-5">

    </div>
  </div>
</div>
