import { Component, OnInit } from '@angular/core';
import {ThemeService} from "../shared/services/theme.service";
import {ApiService} from "../shared/services/api.service";
import {Page} from "../shared/interface/page";

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

  constructor(public theme: ThemeService, private api: ApiService) { }
  page: Page;
  ngOnInit(): void {
    this.api.getPageByType('description').subscribe((e) => {
      this.page = e;
      console.log(e);
    });
  }

}
