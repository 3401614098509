import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../shared/services/theme.service';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../shared/services/api.service";
import {Memorial} from "../shared/interface/memorial";

@Component({
  selector: 'app-current-memorial',
  templateUrl: './current-memorial.component.html',
  styleUrls: ['./current-memorial.component.scss']
})
export class CurrentMemorialComponent implements OnInit {

  constructor(public theme: ThemeService, private activateRouter: ActivatedRoute, private api: ApiService) { }
  memorial: Memorial;
  ngOnInit(): void {
    this.activateRouter.paramMap.subscribe(param => {
      this.api.getMemorialById(param.get('id')).subscribe((e) => {
        this.memorial = e;
      });
    });
  }

}
