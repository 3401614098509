<div class="w-100 h-100 d-flex align-items-start flex-column"
     [class.light-bg]="theme.currentTheme === 'light'"
     [class.dark-bg]="theme.currentTheme === 'dark'"
>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      Экскурсионное обслуживание
    </span>
  </div>
  <div style="width: 100%; margin-top: 1vh; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 10px"></div>
  </div>
  <div class="mar-x" style="padding-top: 1vh">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistRoman, sans-serif;
    font-weight: 500;
    font-size: 64px;">
      Профессиональные экскурсоводы проведус вас по историческим тропам знаменитых некрополей.
      На выбор доступны обзорные и тематические программы о писателях, музыкантах, художниках, скульпторах, архитекторах,
      актерах, государственных деятелях, спортсменах и героях. Каждый маршрут - это погружение в жизнь России той или иной эпохи,
      столлетия и даже года. Слушая увлекательные рассказы о выдающихся личностях, вы узнаете необычайные факты истории нашей страны.
      Аккредитованные экскурсоводы и искусствоведы Москвы доступно и интересно доносят историю до сердца каждого слушателя.
      Наши программы оценят даже самые юные гости.
    </span>
    <div style="padding-top: 1vh; padding-bottom: 1vh; width: 100%; display: flex; justify-content: center; align-items: center">
      <div
        [class.light-bg-line]="theme.currentTheme === 'light'"
        [class.dark-bg-line]="theme.currentTheme === 'dark'"
        style="width: 100%;height: 30px"></div>
    </div>
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif;
    font-weight: bold;
    font-size: 64px;">
      Виды экскурсий
    </span>
    <div style="padding-top: 3vh; padding-bottom: 1vh; width: 100%; display: flex; justify-content: center; align-items: center">
      <div
        [class.light-bg-line]="theme.currentTheme === 'light'"
        [class.dark-bg-line]="theme.currentTheme === 'dark'"
        style="width: 100%;height: 5px"></div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 p-0">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif;
    font-weight: 400;
    font-size: 64px;"> Малая обзорная
    </span>
        </div>
        <div class="col-6 p-0 d-flex justify-content-end align-items-end">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif;
    font-weight: 400;
    font-size: 64px;"> 40 минут
    </span>
        </div>
      </div>
    </div>
    <div style="padding-top: 1vh; padding-bottom: 1vh; width: 100%; display: flex; justify-content: center; align-items: center">
      <div
        [class.light-bg-line]="theme.currentTheme === 'light'"
        [class.dark-bg-line]="theme.currentTheme === 'dark'"
        style="width: 100%;height: 5px"></div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 p-0">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif;
    font-weight: 400;
    font-size: 64px;"> Стандартная обзорная
    </span>
        </div>
        <div class="col-6 p-0 d-flex justify-content-end align-items-end">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif;
    font-weight: 400;
    font-size: 64px;"> 60 минут
    </span>
        </div>
      </div>
    </div>
    <div style="padding-top: 1vh; padding-bottom: 1vh; width: 100%; display: flex; justify-content: center; align-items: center">
      <div
        [class.light-bg-line]="theme.currentTheme === 'light'"
        [class.dark-bg-line]="theme.currentTheme === 'dark'"
        style="width: 100%;height: 5px"></div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 p-0">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
          style="font-family: HumanistRoman, sans-serif;
    font-weight: 400;
    font-size: 64px;"> Тематическая экскурсия
    </span>
        </div>
        <div class="col-6 p-0 d-flex flex-column justify-content-end align-items-end">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif; text-align: end;
    font-weight: 400;
    font-size: 64px;">{{'продолжительность'}}
    </span>
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif; text-align: end;
    font-weight: 400;
    font-size: 64px;">{{'зависит от маршрута'}}
    </span>
        </div>
      </div>
    </div>
    <div style="padding-top: 1vh; padding-bottom: 1vh; width: 100%; display: flex; justify-content: center; align-items: center">

      <div
        [class.light-bg-line]="theme.currentTheme === 'light'"
        [class.dark-bg-line]="theme.currentTheme === 'dark'"
        style="width: 100%;height: 5px"></div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 p-0">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif;
    font-weight: 400;
    font-size: 64px;"> Индивидуальная экскурсия
    </span>
        </div>
        <div class="col-6 p-0 d-flex flex-column justify-content-end align-items-end">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif; text-align: end;
    font-weight: 400;
    font-size: 64px;">{{'продолжительность'}}
    </span>
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif; text-align: end;
    font-weight: 400;
    font-size: 64px;">{{'зависит от маршрута'}}
    </span>
        </div>
      </div>
    </div>
    <div style="padding-top: 1vh; padding-bottom: 1vh; width: 100%; display: flex; justify-content: center; align-items: center">
      <div
        [class.light-bg-line]="theme.currentTheme === 'light'"
        [class.dark-bg-line]="theme.currentTheme === 'dark'"
        style="width: 100%;height: 5px"></div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 p-0">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif;
    font-weight: 400;
    font-size: 64px;"> Индивидуальная экскурсия
    </span>
        </div>
        <div class="col-6 p-0 d-flex flex-column justify-content-end align-items-end">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif; text-align: end;
    font-weight: 400;
    font-size: 64px;">{{'1,5 часа - 2 часа'}}
    </span>
        </div>
      </div>
    </div>
    <div style="padding-top: 3vh; padding-bottom: 1vh; width: 100%; display: flex; justify-content: center; align-items: center">
      <div
        [class.light-bg-line]="theme.currentTheme === 'light'"
        [class.dark-bg-line]="theme.currentTheme === 'dark'"
        style="width: 100%;height: 30px"></div>
    </div>
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; text-align: end;
    font-weight: bold;
    font-size: 64px;">Посетите экскурсии в любой удобный для вас день
    </span>
    <div>
      <span
        [class.light-text]="theme.currentTheme === 'light'"
        [class.dark-text]="theme.currentTheme === 'dark'"
        style="font-family: HumanistRoman, sans-serif; text-align: end;
    font-weight: 400;
    font-size: 64px;">С 1 мая по 30 сентября 9.00 до 19.00
    </span>
    </div>
    <div>
      <span
        [class.light-text]="theme.currentTheme === 'light'"
        [class.dark-text]="theme.currentTheme === 'dark'"
        style="font-family: HumanistRoman, sans-serif; text-align: end;
    font-weight: 400;
    font-size: 64px;">С 1 октября по 30 апреля с 9.00 до 17.00
    </span>
    </div>
  </div>
<!--  <div class="w-100 h-50 d-flex justify-content-center align-items-center">-->
<!--    <span style="color: white"><span style="font-size: 90px">-->
<!--      <app-map></app-map>-->
<!--    </span></span>-->
<!--  </div>-->
</div>
