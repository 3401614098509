<div class="w-100 h-100 d-flex align-items-start flex-column"
     [class.light-bg]="theme.currentTheme === 'light'"
     [class.dark-bg]="theme.currentTheme === 'dark'">
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div
      [class.light-bg-line]="theme.currentTheme === 'light'"
      [class.dark-bg-line]="theme.currentTheme === 'dark'"
      class="mar-x" style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      Схема
    </span>
  </div>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div
      [class.light-bg-line]="theme.currentTheme === 'light'"
      [class.dark-bg-line]="theme.currentTheme === 'dark'"
      class="mar-x" style="width: 100%;height: 10px"></div>
  </div>
  <div style="height: 1vh"></div>
  <div style="width: 100%; display: flex; z-index: 1; justify-content: center; align-items: center">
    <div class="mar-x" style="width: 100%">
      <input [(ngModel)]="searchText" (ngModelChange)="onSearch($event)"
             [class.light-border]="theme.currentTheme === 'light'"
             [class.dark-border]="theme.currentTheme === 'dark'"
             class="w-100 search-input" placeholder="Поиск захоронения"/>
      <div style="max-height: 29%;width: 100%;position: absolute;overflow-y: auto;">
      <ng-container *ngFor="let it of data">
          <div (click)="onSelect(it)" *ngIf="it.name.toLowerCase().includes(searchText.toLowerCase()) && searchText.length" class="autocomplete-card">
        <span style="font-family: HumanistRoman, sans-serif;font-size: 93px;color: #424242;">
          {{it.name}}
        </span>
            <span style="font-family: HumanistRoman, sans-serif;font-size: 50px;color: #717171;">({{it.date | date: 'dd.MM.yyy'}})</span>
          </div>
      </ng-container>
    </div>
    </div>
  </div>
  <div style="height: 2vh"></div>
  <div class="mar-x" *ngIf="selectedItem !== undefined">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      {{selectedItem.name}}
    </span>
    <span style="font-family: HumanistRoman, sans-serif;
    font-size: 60px;
    color: white;">({{selectedItem.date | date: 'dd.MM.yyyy'}} - {{selectedItem.date | date: 'dd.MM.yyyy'}}) - Место {{selectedItem.free ? 'свободно' : 'занято'}}</span>
    <p [innerHTML]="selectedItem.text" class="innterText">{{selectedItem.text}}</p>
  </div>
  <div
    class="w-100"
    [class.light-map]="theme.currentTheme === 'light'"
    [class.dark-map]="theme.currentTheme === 'dark'"
  >
    <svg id="svgPanWrap" #svgMap xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="850.394pt" height="566.929pt" viewBox="0 0 850.394 566.929" version="1.1">
      <defs>
        <g>
          <symbol overflow="visible" id="glyph0-0">
            <path style="stroke:none;" d=""/>
          </symbol>
          <symbol overflow="visible" id="glyph0-1">
            <path style="stroke:none;" d="M 0.28125 -0.265625 C 0.65625 -0.046875 1.265625 0.09375 1.796875 0.09375 C 3.015625 0.09375 4 -0.765625 4 -1.859375 C 4 -2.6875 3.40625 -3.359375 2.625 -3.625 L 2.625 -3.34375 C 3.328125 -3.578125 3.90625 -4.203125 3.90625 -4.921875 C 3.90625 -5.859375 3 -6.65625 1.84375 -6.65625 C 1.359375 -6.65625 0.765625 -6.484375 0.3125 -6.171875 L 0.3125 -5.3125 C 1.109375 -5.84375 1.359375 -5.96875 1.796875 -5.96875 C 2.671875 -5.96875 3.03125 -5.59375 3.03125 -4.890625 C 3.03125 -4.203125 2.6875 -3.890625 1.484375 -3.8125 L 1.484375 -3.09375 C 2.71875 -3.09375 3.09375 -2.6875 3.09375 -1.859375 C 3.09375 -1.03125 2.734375 -0.640625 1.765625 -0.640625 C 1.3125 -0.640625 1.015625 -0.71875 0.21875 -1.140625 L 0.21875 -0.296875 Z M 0.28125 -0.265625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-2">
            <path style="stroke:none;" d="M 3.453125 -4.125 C 3.015625 -3.734375 2.703125 -3.5625 2.265625 -3.5625 C 1.421875 -3.5625 1.09375 -3.953125 1.09375 -4.8125 C 1.09375 -5.546875 1.421875 -5.921875 2.203125 -5.921875 C 3.0625 -5.921875 3.40625 -5.421875 3.40625 -4.359375 C 3.40625 -4.25 3.40625 -4.140625 3.40625 -4.0625 Z M 3.328125 -3.46875 C 3.109375 -2.375 2.390625 -1.453125 0.9375 -0.375 L 1.390625 0.203125 C 3.359375 -1.203125 4.3125 -2.703125 4.3125 -4.265625 C 4.3125 -5.65625 3.375 -6.65625 2.234375 -6.65625 C 1.109375 -6.65625 0.1875 -5.78125 0.1875 -4.71875 C 0.1875 -3.703125 1.140625 -2.828125 2.15625 -2.828125 C 2.671875 -2.828125 3.25 -3.03125 3.25 -3.03125 Z M 3.328125 -3.46875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-3">
            <path style="stroke:none;" d="M 2.078125 0 L 2.75 0 L 2.75 -6.546875 L 1.875 -6.546875 L 1.875 0 Z M 2.078125 0 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-4">
            <path style="stroke:none;" d="M 3.015625 0 L 3.6875 0 L 3.6875 -2.375 L 4.578125 -2.375 L 4.578125 -3.09375 L 3.6875 -3.09375 L 3.6875 -6.640625 L 3.203125 -6.640625 L -0.046875 -2.75 L -0.046875 -2.375 L 2.8125 -2.375 L 2.8125 0 Z M 3.015625 -3.09375 L 1.171875 -3.09375 L 3.15625 -5.453125 L 2.8125 -5.5625 L 2.8125 -3.09375 Z M 3.015625 -3.09375 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-5">
            <path style="stroke:none;" d="M 0.109375 -3.265625 C 0.109375 -1.1875 1.140625 0.109375 2.3125 0.109375 C 3.484375 0.109375 4.515625 -1.1875 4.515625 -3.265625 C 4.515625 -5.34375 3.484375 -6.65625 2.3125 -6.65625 C 1.140625 -6.65625 0.109375 -5.34375 0.109375 -3.265625 Z M 1.015625 -3.265625 C 1.015625 -5.078125 1.4375 -5.921875 2.3125 -5.921875 C 3.1875 -5.921875 3.609375 -5.0625 3.609375 -3.265625 C 3.609375 -1.484375 3.1875 -0.625 2.3125 -0.625 C 1.4375 -0.625 1.015625 -1.46875 1.015625 -3.265625 Z M 1.015625 -3.265625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-6">
            <path style="stroke:none;" d="M 1.0625 -0.078125 L 1.59375 0.171875 L 4.5625 -6.34375 L 4.5625 -6.546875 L 0.0625 -6.546875 L 0.0625 -5.828125 L 3.421875 -5.828125 L 0.828125 -0.171875 Z M 1.0625 -0.078125 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-7">
            <path style="stroke:none;" d="M 0.34375 -0.390625 C 0.78125 -0.078125 1.421875 0.109375 1.953125 0.109375 C 3.234375 0.109375 4.265625 -0.875 4.265625 -2.0625 C 4.265625 -3.265625 3.296875 -4.15625 2.015625 -4.15625 C 1.859375 -4.15625 1.6875 -4.15625 1.703125 -4.15625 L 1.703125 -5.828125 L 3.90625 -5.828125 L 3.90625 -6.546875 L 0.8125 -6.546875 L 0.8125 -3.328125 C 1.390625 -3.421875 1.6875 -3.453125 1.90625 -3.453125 C 2.953125 -3.453125 3.34375 -3 3.34375 -2.0625 C 3.34375 -1.140625 2.921875 -0.640625 2.015625 -0.640625 C 1.515625 -0.640625 1.140625 -0.765625 0.28125 -1.375 L 0.28125 -0.4375 Z M 0.34375 -0.390625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-8">
            <path style="stroke:none;" d="M 1.140625 -2.421875 C 1.578125 -2.828125 1.90625 -2.984375 2.34375 -2.984375 C 3.203125 -2.984375 3.515625 -2.59375 3.515625 -1.75 C 3.515625 -1.015625 3.1875 -0.640625 2.40625 -0.640625 C 1.546875 -0.640625 1.1875 -1.125 1.1875 -2.1875 C 1.1875 -2.296875 1.203125 -2.40625 1.203125 -2.484375 Z M 1.28125 -3.078125 C 1.484375 -4.1875 2.203125 -5.09375 3.65625 -6.1875 L 3.21875 -6.75 C 1.234375 -5.34375 0.28125 -3.84375 0.28125 -2.28125 C 0.28125 -0.890625 1.234375 0.109375 2.375 0.109375 C 3.5 0.109375 4.421875 -0.765625 4.421875 -1.828125 C 4.421875 -2.84375 3.453125 -3.71875 2.453125 -3.71875 C 1.9375 -3.71875 1.359375 -3.515625 1.359375 -3.515625 Z M 1.28125 -3.078125 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-9">
            <path style="stroke:none;" d="M 1.1875 -1.828125 C 1.1875 -2.578125 1.59375 -3.0625 2.328125 -3.0625 C 3.0625 -3.0625 3.4375 -2.59375 3.4375 -1.828125 C 3.4375 -1.0625 3.0625 -0.609375 2.3125 -0.609375 C 1.59375 -0.609375 1.1875 -1.078125 1.1875 -1.828125 Z M 1.28125 -4.84375 C 1.28125 -5.546875 1.640625 -5.953125 2.328125 -5.953125 C 3.015625 -5.953125 3.34375 -5.546875 3.34375 -4.84375 C 3.34375 -4.15625 3 -3.75 2.328125 -3.75 C 1.65625 -3.75 1.28125 -4.15625 1.28125 -4.84375 Z M 1.296875 -3.484375 C 0.6875 -3.109375 0.296875 -2.484375 0.296875 -1.828125 C 0.296875 -0.8125 1.265625 0.109375 2.3125 0.109375 C 3.359375 0.109375 4.34375 -0.796875 4.34375 -1.828125 C 4.34375 -2.484375 3.953125 -3.109375 3.484375 -3.40625 C 3.859375 -3.640625 4.203125 -4.25 4.203125 -4.84375 C 4.203125 -5.78125 3.28125 -6.65625 2.3125 -6.65625 C 1.34375 -6.65625 0.421875 -5.765625 0.421875 -4.84375 C 0.421875 -4.25 0.765625 -3.640625 1.140625 -3.390625 Z M 1.296875 -3.484375 "/>
          </symbol>
          <symbol overflow="visible" id="glyph0-10">
            <path style="stroke:none;" d="M 0.375 0 L 4.53125 0 L 4.53125 -0.75 L 1.75 -0.75 C 3.078125 -2.234375 3.9375 -3.640625 3.9375 -4.671875 C 3.9375 -5.765625 2.984375 -6.65625 1.890625 -6.65625 C 1.375 -6.65625 0.765625 -6.4375 0.25 -6 L 0.25 -5.015625 C 1.0625 -5.765625 1.359375 -5.953125 1.796875 -5.953125 C 2.671875 -5.953125 3.046875 -5.53125 3.046875 -4.703125 C 3.046875 -3.703125 2.171875 -2.265625 0.171875 -0.25 L 0.171875 0 Z M 0.375 0 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-0">
            <path style="stroke:none;" d=""/>
          </symbol>
          <symbol overflow="visible" id="glyph1-1">
            <path style="stroke:none;" d="M 1.609375 -3.53125 C 1.890625 -4.46875 2.515625 -5.109375 3.609375 -5.875 L 2.9375 -6.71875 C 1.078125 -5.46875 0.171875 -3.90625 0.171875 -2.265625 C 0.171875 -0.90625 1.125 0.09375 2.296875 0.09375 C 3.453125 0.09375 4.4375 -0.84375 4.4375 -1.953125 C 4.4375 -3.015625 3.53125 -3.953125 2.5 -3.953125 C 2.203125 -3.953125 1.859375 -3.859375 1.703125 -3.78125 Z M 1.421875 -2.46875 C 1.703125 -2.734375 1.9375 -2.84375 2.359375 -2.84375 C 2.9375 -2.84375 3.203125 -2.546875 3.203125 -1.90625 C 3.203125 -1.28125 2.96875 -1.015625 2.375 -1.015625 C 1.703125 -1.015625 1.421875 -1.390625 1.421875 -2.171875 C 1.421875 -2.296875 1.4375 -2.421875 1.453125 -2.5 Z M 1.421875 -2.46875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-2">
            <path style="stroke:none;" d="M 1.859375 0 L 2.90625 0 L 2.90625 -6.546875 L 1.703125 -6.546875 L 1.703125 0 Z M 1.859375 0 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-3">
            <path style="stroke:none;" d="M 0.09375 -3.265625 C 0.09375 -1.1875 1.109375 0.109375 2.3125 0.109375 C 3.515625 0.109375 4.53125 -1.1875 4.53125 -3.265625 C 4.53125 -5.34375 3.515625 -6.65625 2.3125 -6.65625 C 1.109375 -6.65625 0.09375 -5.34375 0.09375 -3.265625 Z M 1.3125 -3.265625 C 1.3125 -4.84375 1.59375 -5.53125 2.3125 -5.53125 C 3.03125 -5.53125 3.3125 -4.84375 3.3125 -3.265625 C 3.3125 -1.6875 3.03125 -1.015625 2.3125 -1.015625 C 1.59375 -1.015625 1.3125 -1.6875 1.3125 -3.265625 Z M 1.3125 -3.265625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-4">
            <path style="stroke:none;" d="M 2.984375 -3.015625 C 2.734375 -2.15625 2.125 -1.46875 0.984375 -0.6875 L 1.65625 0.171875 C 3.5 -1.0625 4.421875 -2.640625 4.421875 -4.296875 C 4.421875 -5.65625 3.46875 -6.65625 2.3125 -6.65625 C 1.140625 -6.65625 0.15625 -5.703125 0.15625 -4.59375 C 0.15625 -3.53125 1.078125 -2.59375 2.109375 -2.59375 C 2.40625 -2.59375 2.75 -2.6875 2.90625 -2.765625 Z M 3.171875 -4.09375 C 2.890625 -3.8125 2.671875 -3.71875 2.25 -3.71875 C 1.65625 -3.71875 1.390625 -4.015625 1.390625 -4.65625 C 1.390625 -5.28125 1.640625 -5.546875 2.234375 -5.546875 C 2.90625 -5.546875 3.1875 -5.15625 3.1875 -4.375 C 3.1875 -4.25 3.15625 -4.125 3.140625 -4.046875 Z M 3.171875 -4.09375 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-5">
            <path style="stroke:none;" d="M 0.9375 -0.1875 L 1.796875 0.203125 L 4.78125 -6.546875 L 0.390625 -6.546875 L 0.390625 -5.453125 L 3 -5.453125 L 0.75 -0.28125 Z M 0.9375 -0.1875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-6">
            <path style="stroke:none;" d="M 1.359375 -1.921875 C 1.359375 -2.5 1.640625 -2.796875 2.328125 -2.796875 C 2.984375 -2.796875 3.25 -2.53125 3.25 -1.921875 C 3.25 -1.3125 2.984375 -1.015625 2.28125 -1.015625 C 1.640625 -1.015625 1.359375 -1.328125 1.359375 -1.921875 Z M 3.171875 -4.765625 C 3.171875 -4.140625 2.890625 -3.875 2.3125 -3.875 C 1.78125 -3.875 1.46875 -4.15625 1.46875 -4.765625 C 1.46875 -5.3125 1.71875 -5.5625 2.328125 -5.5625 C 2.9375 -5.5625 3.171875 -5.328125 3.171875 -4.765625 Z M 1.171875 -3.515625 C 0.59375 -3.3125 0.078125 -2.625 0.078125 -1.9375 C 0.078125 -0.859375 1.09375 0.109375 2.3125 0.109375 C 3.515625 0.109375 4.53125 -0.859375 4.53125 -1.921875 C 4.53125 -2.625 4.03125 -3.3125 3.78125 -3.40625 C 4.03125 -3.5625 4.34375 -4.1875 4.34375 -4.78125 C 4.34375 -5.765625 3.421875 -6.65625 2.359375 -6.65625 C 1.234375 -6.65625 0.296875 -5.78125 0.296875 -4.78125 C 0.296875 -4.1875 0.59375 -3.578125 0.859375 -3.40625 Z M 1.171875 -3.515625 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-7">
            <path style="stroke:none;" d="M 0.3125 0 L 4.453125 0 L 4.453125 -1.1875 L 2.234375 -1.1875 C 3.265625 -2.375 4.015625 -3.53125 4.015625 -4.625 C 4.015625 -5.765625 3.109375 -6.65625 2 -6.65625 C 1.484375 -6.65625 0.875 -6.4375 0.3125 -6.03125 L 0.3125 -4.890625 L 0.59375 -4.78125 C 1.0625 -5.296875 1.421875 -5.515625 1.890625 -5.515625 C 2.53125 -5.515625 2.796875 -5.203125 2.796875 -4.515625 C 2.796875 -3.984375 2.546875 -3.421875 1.96875 -2.609375 C 1.65625 -2.15625 1 -1.421875 0.03125 0 Z M 0.3125 0 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-8">
            <path style="stroke:none;" d="M 0.484375 -0.296875 C 0.9375 -0.0625 1.46875 0.109375 1.921875 0.109375 C 3.15625 0.109375 4.109375 -0.84375 4.109375 -2 C 4.109375 -2.671875 3.625 -3.359375 3.328125 -3.5 C 3.671875 -3.71875 3.96875 -4.296875 3.96875 -4.875 C 3.96875 -5.84375 3.09375 -6.65625 1.9375 -6.65625 C 1.53125 -6.65625 1.140625 -6.5625 0.5625 -6.296875 L 0.5625 -5.203125 C 1.125 -5.453125 1.453125 -5.5625 1.78125 -5.5625 C 2.484375 -5.5625 2.765625 -5.3125 2.765625 -4.78125 C 2.765625 -4.203125 2.46875 -3.9375 1.5 -3.9375 L 1.5 -2.875 C 2.546875 -2.8125 2.828125 -2.59375 2.828125 -1.96875 C 2.828125 -1.359375 2.609375 -1.109375 1.953125 -1.109375 C 1.546875 -1.109375 1.21875 -1.1875 0.4375 -1.59375 L 0.4375 -0.328125 Z M 0.484375 -0.296875 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-9">
            <path style="stroke:none;" d="M 0.3125 -0.34375 C 0.828125 -0.078125 1.421875 0.109375 1.84375 0.109375 C 3.140625 0.109375 4.1875 -0.921875 4.1875 -2.078125 C 4.1875 -3.25 3.203125 -4.1875 1.8125 -4.1875 L 1.84375 -4.1875 L 1.90625 -5.515625 L 3.890625 -5.515625 L 3.890625 -6.546875 L 0.796875 -6.546875 L 0.6875 -2.96875 C 1.109375 -3.078125 1.3125 -3.109375 1.484375 -3.109375 C 2.546875 -3.109375 2.953125 -2.796875 2.953125 -2.0625 C 2.953125 -1.390625 2.625 -1.078125 1.828125 -1.078125 C 1.390625 -1.078125 1.015625 -1.1875 0.265625 -1.640625 L 0.265625 -0.375 Z M 0.3125 -0.34375 "/>
          </symbol>
          <symbol overflow="visible" id="glyph1-10">
            <path style="stroke:none;" d="M 2.734375 -3.109375 L 1.5625 -3.109375 L 2.875 -4.765625 L 2.59375 -4.875 L 2.578125 -3.109375 Z M 2.734375 0 L 3.75 0 L 3.75 -2.09375 L 4.453125 -2.09375 L 4.453125 -3.109375 L 3.75 -3.109375 L 3.75 -6.65625 L 3.140625 -6.65625 L -0.109375 -2.640625 L -0.109375 -2.09375 L 2.578125 -2.09375 L 2.578125 0 Z M 2.734375 0 "/>
          </symbol>
        </g>
      </defs>
      <g #panspec id="surface1">
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 456.261719 76.285156 L 456.261719 91.335938 L 486.363281 91.335938 L 486.363281 81.660156 L 680.949219 81.660156 L 680.949219 267.644531 L 666.972656 267.644531 L 666.972656 471.902344 L 451.960938 459.003906 L 195.023438 353.648438 L 126.21875 288.070313 L 126.21875 224.640625 L 195.023438 76.285156 Z M 462.007813 70.539063 L 191.351563 70.539063 L 189.808594 73.867188 L 121.003906 222.222656 L 120.472656 223.375 L 120.472656 290.53125 L 122.253906 292.230469 L 191.058594 357.808594 L 191.839844 358.554688 L 192.839844 358.964844 L 449.78125 464.320313 L 450.664063 464.683594 L 451.617188 464.738281 L 666.628906 477.640625 L 672.71875 478.007813 L 672.71875 273.390625 L 686.695313 273.390625 L 686.695313 75.914063 L 480.617188 75.914063 L 480.617188 85.589844 L 462.007813 85.589844 Z M 462.007813 70.539063 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 346.382813 392.765625 L 386.070313 392.765625 L 386.070313 329.996094 L 346.382813 329.996094 Z M 347.53125 331.148438 L 384.921875 331.148438 L 384.921875 391.617188 L 347.53125 391.617188 Z M 347.53125 331.148438 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 346.382813 324.25 L 386.070313 324.25 L 386.070313 268.535156 L 346.382813 268.25 Z M 347.53125 269.40625 L 384.917969 269.675781 L 384.917969 323.101563 L 347.53125 323.101563 Z M 347.53125 269.40625 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 449.941406 82.605469 L 199.058594 82.605469 L 192.597656 96.53125 L 449.941406 96.53125 Z M 448.789063 95.382813 L 194.398438 95.382813 L 199.792969 83.753906 L 448.789063 83.753906 Z M 448.789063 95.382813 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 207.394531 109.402344 L 450.296875 109.402344 L 450.296875 97.683594 L 207.394531 97.683594 Z M 208.542969 98.832031 L 449.148438 98.832031 L 449.148438 108.253906 L 208.542969 108.253906 Z M 208.542969 98.832031 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 207.394531 324.25 L 340.636719 324.25 L 340.636719 268.207031 L 207.394531 267.25 Z M 208.542969 268.40625 L 339.488281 269.347656 L 339.488281 323.101563 L 208.542969 323.101563 Z M 208.542969 268.40625 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 132.539063 226.035156 L 132.539063 260.964844 L 201.648438 261.460938 L 201.648438 176.003906 L 155.742188 176.003906 Z M 200.5 177.152344 L 200.5 260.304688 L 133.6875 259.820313 L 133.6875 226.289063 L 156.476563 177.152344 Z M 200.5 177.152344 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 207.394531 351.890625 L 340.636719 406.523438 L 340.636719 330 L 207.394531 330 Z M 208.542969 331.148438 L 339.488281 331.148438 L 339.488281 404.8125 L 208.542969 351.117188 Z M 208.542969 331.148438 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 132.539063 285.363281 L 198.519531 348.253906 L 201.648438 349.535156 L 201.648438 267.207031 L 132.539063 266.710938 Z M 133.6875 267.867188 L 200.5 268.347656 L 200.5 347.820313 L 199.160156 347.269531 L 133.6875 284.871094 Z M 133.6875 267.867188 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 346.382813 408.878906 L 386.070313 425.15625 L 386.070313 398.511719 L 346.382813 398.511719 Z M 347.53125 399.660156 L 384.917969 399.660156 L 384.917969 423.441406 L 347.53125 408.109375 Z M 347.53125 399.660156 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 158.40625 170.257813 L 201.648438 170.257813 L 201.648438 97.683594 L 192.066406 97.683594 Z M 200.5 98.832031 L 200.5 169.109375 L 160.207031 169.109375 L 192.800781 98.832031 Z M 200.5 98.832031 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 582.882813 460.527344 L 630.746094 463.398438 L 630.746094 398.511719 L 582.882813 398.511719 Z M 584.03125 399.660156 L 629.597656 399.660156 L 629.597656 462.179688 L 584.03125 459.445313 Z M 584.03125 399.660156 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 613.570313 338.667969 L 613.570313 329.996094 L 582.882813 329.996094 L 582.882813 392.765625 L 630.746094 392.765625 L 630.746094 357.414063 L 620.847656 353.3125 Z M 629.59375 391.617188 L 584.03125 391.617188 L 584.03125 331.148438 L 612.421875 331.148438 L 612.421875 338.941406 L 612.542969 339.183594 L 619.820313 353.820313 L 620.011719 354.207031 L 620.40625 354.371094 L 629.59375 358.179688 Z M 629.59375 391.617188 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 530.171875 324.25 L 577.136719 324.25 L 577.136719 269.910156 L 530.171875 269.574219 Z M 531.320313 270.730469 L 575.988281 271.050781 L 575.988281 323.101563 L 531.320313 323.101563 Z M 531.320313 270.730469 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 530.171875 457.363281 L 577.136719 460.183594 L 577.136719 398.511719 L 530.171875 398.511719 Z M 531.320313 399.664063 L 575.988281 399.664063 L 575.988281 458.960938 L 531.320313 456.28125 Z M 531.320313 399.664063 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 530.171875 392.765625 L 577.136719 392.765625 L 577.136719 329.996094 L 530.171875 329.996094 Z M 531.320313 331.148438 L 575.988281 331.148438 L 575.988281 391.617188 L 531.320313 391.617188 Z M 531.320313 331.148438 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 478.707031 313.089844 L 490.585938 313.089844 L 490.585938 324.25 L 524.425781 324.25 L 524.425781 269.53125 L 478.707031 269.203125 Z M 479.855469 270.359375 L 523.277344 270.671875 L 523.277344 323.101563 L 491.734375 323.101563 L 491.734375 311.9375 L 479.855469 311.9375 Z M 479.855469 270.359375 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 478.707031 170.257813 L 674.628906 170.257813 L 674.628906 110.550781 L 478.707031 110.550781 Z M 479.855469 111.699219 L 673.476563 111.699219 L 673.476563 169.109375 L 479.855469 169.109375 Z M 479.855469 111.699219 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 582.882813 324.25 L 613.570313 324.25 L 613.570313 318.570313 L 621.648438 302.628906 L 630.746094 300.082031 L 630.746094 270.296875 L 582.882813 269.953125 Z M 584.03125 271.109375 L 629.597656 271.4375 L 629.597656 299.210938 L 621.339844 301.523438 L 620.855469 301.660156 L 620.625 302.109375 L 612.546875 318.050781 L 612.421875 318.296875 L 612.421875 323.101563 L 584.03125 323.101563 Z M 584.03125 271.109375 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 674.625 97.683594 L 493.164063 97.683594 L 493.164063 109.402344 L 674.625 109.402344 L 674.625 87.980469 L 492.683594 87.980469 L 492.683594 96.535156 L 674.625 96.535156 Z M 673.476563 108.253906 L 494.316406 108.253906 L 494.316406 98.832031 L 673.476563 98.832031 Z M 673.476563 95.382813 L 493.832031 95.382813 L 493.832031 89.128906 L 673.476563 89.128906 Z M 673.476563 95.382813 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 478.707031 263.457031 L 577.136719 264.164063 L 582.882813 264.207031 L 660.652344 264.765625 L 660.652344 261.324219 L 674.625 261.324219 L 674.625 176.003906 L 478.707031 176.003906 Z M 479.855469 177.152344 L 673.476563 177.152344 L 673.476563 260.175781 L 659.5 260.175781 L 659.5 263.609375 L 582.890625 263.058594 L 577.144531 263.015625 L 479.855469 262.316406 Z M 479.855469 177.152344 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 426.667969 324.25 L 459.109375 324.25 L 459.109375 313.089844 L 472.960938 313.089844 L 472.960938 269.160156 L 426.667969 268.828125 Z M 427.816406 269.984375 L 471.8125 270.300781 L 471.8125 311.9375 L 457.960938 311.9375 L 457.960938 323.101563 L 427.816406 323.101563 Z M 427.816406 269.984375 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 524.425781 392.765625 L 524.425781 329.996094 L 490.585938 329.996094 L 490.585938 392.671875 L 490.601563 392.671875 M 491.734375 331.148438 L 523.277344 331.148438 L 523.277344 391.617188 L 491.734375 391.617188 Z M 491.734375 331.148438 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 426.667969 441.800781 L 453.386719 452.757813 L 472.960938 453.929688 L 472.960938 398.511719 L 426.667969 398.511719 Z M 427.816406 399.660156 L 471.8125 399.660156 L 471.8125 452.710938 L 453.644531 451.621094 L 427.816406 441.03125 Z M 427.816406 399.660156 "/>
        <path class="test" style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 459.109375 392.671875 L 459.109375 329.996094 L 426.667969 329.996094 L 426.667969 392.765625 L 459.066406 392.777344 M 457.960938 391.617188 L 427.816406 391.617188 L 427.816406 331.148438 L 457.960938 331.148438 Z M 457.960938 391.617188 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 391.816406 392.765625 L 420.921875 392.765625 L 420.921875 329.996094 L 391.816406 329.996094 Z M 392.964844 331.148438 L 419.773438 331.148438 L 419.773438 391.617188 L 392.964844 391.617188 Z M 392.964844 331.148438 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 391.816406 324.25 L 420.921875 324.25 L 420.921875 268.785156 L 391.816406 268.578125 Z M 392.964844 269.734375 L 419.773438 269.925781 L 419.773438 323.101563 L 392.964844 323.101563 Z M 392.964844 269.734375 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 478.707031 454.277344 L 524.425781 457.019531 L 524.425781 398.511719 L 478.707031 398.511719 Z M 479.855469 399.660156 L 523.277344 399.660156 L 523.277344 455.800781 L 479.855469 453.195313 Z M 479.855469 399.660156 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 207.394531 170.257813 L 472.960938 170.257813 L 472.960938 110.550781 L 207.394531 110.550781 Z M 208.542969 111.699219 L 471.8125 111.699219 L 471.8125 169.109375 L 208.542969 169.109375 Z M 208.542969 111.699219 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 207.394531 261.503906 L 472.960938 263.414063 L 472.960938 176.003906 L 207.394531 176.003906 Z M 208.542969 177.152344 L 471.8125 177.152344 L 471.8125 262.257813 L 208.542969 260.363281 Z M 208.542969 177.152344 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 391.816406 427.511719 L 420.921875 439.445313 L 420.921875 398.511719 L 391.816406 398.511719 Z M 392.964844 399.664063 L 419.773438 399.664063 L 419.773438 437.730469 L 392.964844 426.738281 Z M 392.964844 399.664063 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 674.472656 97.683594 L 674.894531 97.683594 L 674.894531 96.535156 L 674.472656 96.535156 Z M 674.472656 97.683594 "/>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-1" x="339.2087" y="142.6622"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-2" x="185.607883" y="142.6622"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-3" x="575.710011" y="142.6622"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-4" x="339.2087" y="223.448121"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-3" x="166.972051" y="223.448121"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-5" x="171.665486" y="223.448121"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-3" x="167.348997" y="289.248147"/>
          <use xlink:href="#glyph0-3" x="171.283943" y="289.248147"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-6" x="275.679542" y="296.575606"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-7" x="364.776658" y="299.021157"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-8" x="364.776658" y="362.366439"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-9" x="364.776658" y="409.457082"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-7" x="404.034184" y="299.021157"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-8" x="404.034184" y="362.366439"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-9" x="404.034184" y="420.857394"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-7" x="446.868098" y="299.021157"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-8" x="441.167942" y="362.366439"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-9" x="447.686346" y="429.003101"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-7" x="499.971487" y="299.021157"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-8" x="504.04434" y="362.366439"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-9" x="499.162432" y="429.003101"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-7" x="553.074876" y="299.021157"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-8" x="553.074876" y="362.366439"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-9" x="553.074876" y="429.003101"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-7" x="605.038233" y="299.021157"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-8" x="605.038233" y="362.366439"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-9" x="605.038233" y="429.003101"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-6" x="275.688736" y="353.58636"/>
        </g>
        <g style="fill:rgb(0%,0%,0%);fill-opacity:1;">
          <use xlink:href="#glyph0-10" x="575.719205" y="223.438927"/>
        </g>
        <path style="fill:none;stroke-width:5.746;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 0.00059375 0.00130625 L 203.840438 0.00130625 " transform="matrix(1,0,0,-1,481.1205,81.9974)"/>
        <path style="fill:none;stroke-width:5.746;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 0.00074375 0.00125625 L 0.00074375 -92.029994 " transform="matrix(1,0,0,-1,680.6516,175.6536)"/>
        <path style="fill:none;stroke-width:8.045;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 0.00106875 -0.0006625 L 479.669038 -2.442069 " transform="matrix(1,0,0,-1,205.2919,264.9134)"/>
        <path style="fill:none;stroke-width:8.045;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 0.00065625 -0.00071875 L 0.00065625 -148.602281 " transform="matrix(1,0,0,-1,208.722,175.6985)"/>
        <path style="fill:none;stroke-width:6.895;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 0.00065625 0.00125625 L 0.00065625 -34.069056 " transform="matrix(1,0,0,-1,208.722,329.9036)"/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 472.929688 272.082031 L 478.675781 272.082031 L 478.675781 261.464844 L 472.929688 261.464844 Z M 472.929688 272.082031 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 205.910156 346.9375 L 211.53125 346.9375 L 211.53125 340.023438 L 205.910156 340.023438 Z M 205.910156 346.9375 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-1" x="205.9103" y="346.9388"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 577.417969 83.242188 L 583.039063 83.242188 L 583.039063 76.328125 L 577.417969 76.328125 Z M 577.417969 83.242188 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-2" x="577.4172" y="83.2414"/>
        </g>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-2" x="337.514183" y="268.165493"/>
          <use xlink:href="#glyph1-2" x="341.623811" y="268.165493"/>
        </g>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-2" x="203.735199" y="225.653362"/>
          <use xlink:href="#glyph1-3" x="207.513851" y="225.653362"/>
        </g>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-4" x="206.732378" y="300.693158"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 156.171875 323.488281 L 161.792969 323.488281 L 161.792969 316.574219 L 156.171875 316.574219 Z M 156.171875 323.488281 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-5" x="156.1697" y="323.4869"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 123.0625 256.914063 L 128.683594 256.914063 L 128.683594 250 L 123.0625 250 Z M 123.0625 256.914063 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-6" x="123.0619" y="256.9143"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 144.769531 180.269531 L 150.390625 180.269531 L 150.390625 173.355469 L 144.769531 173.355469 Z M 144.769531 180.269531 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-4" x="144.768" y="180.2697"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 339.859375 78.175781 L 345.480469 78.175781 L 345.480469 71.261719 L 339.859375 71.261719 Z M 339.859375 78.175781 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-7" x="339.8586" y="78.1745"/>
        </g>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-2" x="568.572763" y="269.846842"/>
          <use xlink:href="#glyph1-7" x="573.105306" y="269.846842"/>
        </g>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-2" x="677.353804" y="232.648728"/>
          <use xlink:href="#glyph1-8" x="681.904735" y="232.648728"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 665.914063 429.011719 L 671.539063 429.011719 L 671.539063 422.097656 L 665.914063 422.097656 Z M 665.914063 429.011719 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-8" x="665.9155" y="429.0097"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 295.027344 399.617188 L 299.671875 399.617188 L 299.671875 392.703125 L 295.027344 392.703125 Z M 295.027344 399.617188 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-9" x="295.0271" y="399.6182"/>
        </g>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 515.402344 466.675781 L 521.027344 466.675781 L 521.027344 459.761719 L 515.402344 459.761719 Z M 515.402344 466.675781 "/>
        <g style="fill:rgb(100%,100%,100%);fill-opacity:1;">
          <use xlink:href="#glyph1-10" x="515.4033" y="466.6761"/>
        </g>
        <path style="fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:10;" d="M -0.00129375 0.0013625 C 0.803394 0.0873 1.4323 0.770894 1.4323 1.599019 L -1.782544 1.599019 C -1.782544 0.766987 -1.149731 0.0833937 -0.341137 -0.00254375 L -0.341137 -6.623638 L -2.501294 -6.623638 L -2.485669 -8.959575 C -2.485669 -10.182231 -1.540356 -11.182231 -0.341137 -11.264263 L -0.341137 -11.272075 L -0.00129375 -11.272075 L -0.00129375 -11.264263 C 1.194019 -11.174419 2.135425 -10.178325 2.135425 -8.959575 L 2.158863 -6.623638 L -0.00129375 -6.623638 Z M -0.00129375 0.0013625 " transform="matrix(1,0,0,-1,651.7552,390.8998)"/>
        <path style="fill-rule:nonzero;fill:rgb(100%,100%,100%);fill-opacity:1;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:10;" d="M -0.000375 0.00091875 L -0.000375 8.969669 C 0.800406 9.055606 1.4215 9.735294 1.4215 10.555606 L -1.773813 10.555606 C -1.773813 9.731387 -1.144906 9.0517 -0.340219 8.969669 L -0.340219 0.00091875 L -2.492563 0.00091875 L -2.492563 -2.315488 L -2.152719 -2.315488 L -2.152719 -0.342831 L -1.418344 -0.342831 L -1.418344 -2.319394 L -1.0785 -2.319394 L -1.0785 -0.342831 L -0.344125 -0.342831 L -0.344125 -2.319394 L -0.00428125 -2.319394 L -0.00428125 -0.342831 L 0.726187 -0.342831 L 0.726187 -2.319394 L 1.069937 -2.319394 L 1.069937 -0.342831 L 1.800406 -0.342831 L 1.800406 -2.315488 L 2.144156 -2.315488 L 2.144156 0.00091875 Z M -0.000375 0.00091875 " transform="matrix(1,0,0,-1,658.2035,399.8642)"/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 651.753906 390.898438 C 652.5625 390.8125 653.1875 390.128906 653.1875 389.300781 L 649.972656 389.300781 C 649.972656 390.132813 650.605469 390.816406 651.414063 390.902344 L 651.414063 397.523438 L 649.253906 397.523438 L 649.269531 399.859375 C 649.269531 401.082031 650.214844 402.082031 651.414063 402.164063 L 651.414063 402.171875 L 651.753906 402.171875 L 651.753906 402.164063 C 652.949219 402.074219 653.890625 401.078125 653.890625 399.859375 L 653.914063 397.523438 L 651.753906 397.523438 Z M 650.359375 389.644531 L 652.800781 389.644531 C 652.652344 390.175781 652.160156 390.570313 651.582031 390.570313 C 651 390.570313 650.511719 390.175781 650.359375 389.644531 M 649.609375 399.859375 L 649.597656 397.863281 L 651.414063 397.863281 L 651.414063 401.824219 C 650.40625 401.738281 649.609375 400.890625 649.609375 399.859375 M 653.570313 397.863281 L 653.550781 399.859375 C 653.550781 400.886719 652.761719 401.734375 651.753906 401.824219 L 651.753906 397.863281 Z M 653.570313 397.863281 "/>
        <path style="fill:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:10;" d="M -0.00189375 0.0013625 C 0.8067 0.0873 1.4317 0.770894 1.4317 1.599019 L -1.783144 1.599019 C -1.783144 0.766987 -1.150331 0.0833937 -0.341738 -0.00254375 L -0.341738 -6.623638 L -2.501894 -6.623638 L -2.486269 -8.959575 C -2.486269 -10.182231 -1.540956 -11.182231 -0.341738 -11.264263 L -0.341738 -11.272075 L -0.00189375 -11.272075 L -0.00189375 -11.264263 C 1.193419 -11.174419 2.134825 -10.178325 2.134825 -8.959575 L 2.158262 -6.623638 L -0.00189375 -6.623638 Z M -1.396425 1.255269 L 1.044981 1.255269 C 0.896544 0.724019 0.404356 0.329487 -0.173769 0.329487 C -0.7558 0.329487 -1.244081 0.724019 -1.396425 1.255269 Z M -2.146425 -8.959575 L -2.158144 -6.963481 L -0.341738 -6.963481 L -0.341738 -10.924419 C -1.34955 -10.838481 -2.146425 -9.990825 -2.146425 -8.959575 Z M 1.814512 -6.963481 L 1.794981 -8.959575 C 1.794981 -9.986919 1.005919 -10.834575 -0.00189375 -10.924419 L -0.00189375 -6.963481 Z M 1.814512 -6.963481 " transform="matrix(1,0,0,-1,651.7558,390.8998)"/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 658.203125 399.863281 L 658.203125 390.894531 C 659.003906 390.808594 659.628906 390.128906 659.628906 389.308594 L 656.429688 389.308594 C 656.429688 390.132813 657.058594 390.8125 657.863281 390.898438 L 657.863281 399.863281 L 655.710938 399.863281 L 655.710938 402.179688 L 656.050781 402.179688 L 656.050781 400.207031 L 656.785156 400.207031 L 656.785156 402.183594 L 657.125 402.183594 L 657.125 400.207031 L 657.859375 400.207031 L 657.859375 402.183594 L 658.199219 402.183594 L 658.199219 400.207031 L 658.933594 400.207031 L 658.933594 402.183594 L 659.273438 402.183594 L 659.273438 400.207031 L 660.007813 400.207031 L 660.007813 402.179688 L 660.347656 402.179688 L 660.347656 399.863281 Z M 656.820313 389.648438 L 659.238281 389.648438 C 659.089844 390.175781 658.605469 390.5625 658.027344 390.5625 C 657.453125 390.5625 656.96875 390.175781 656.820313 389.648438 "/>
        <path style="fill:none;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:10;" d="M -0.000975 0.00091875 L -0.000975 8.969669 C 0.799806 9.055606 1.424806 9.735294 1.424806 10.555606 L -1.774413 10.555606 C -1.774413 9.731387 -1.145506 9.0517 -0.340819 8.965762 L -0.340819 0.00091875 L -2.493163 0.00091875 L -2.493163 -2.315488 L -2.153319 -2.315488 L -2.153319 -0.342831 L -1.418944 -0.342831 L -1.418944 -2.319394 L -1.0791 -2.319394 L -1.0791 -0.342831 L -0.344725 -0.342831 L -0.344725 -2.319394 L -0.00488125 -2.319394 L -0.00488125 -0.342831 L 0.729494 -0.342831 L 0.729494 -2.319394 L 1.069337 -2.319394 L 1.069337 -0.342831 L 1.803712 -0.342831 L 1.803712 -2.315488 L 2.143556 -2.315488 L 2.143556 0.00091875 Z M -1.383788 10.215762 L 1.034181 10.215762 C 0.885744 9.688419 0.401369 9.3017 -0.176756 9.3017 C -0.750975 9.3017 -1.23535 9.688419 -1.383788 10.215762 Z M -1.383788 10.215762 " transform="matrix(1,0,0,-1,658.2041,399.8642)"/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 458.9375 208.421875 L 454.164063 207.726563 L 452.03125 203.402344 L 449.898438 207.726563 L 445.125 208.421875 L 448.578125 211.785156 L 447.765625 216.539063 L 452.03125 214.292969 L 456.300781 216.539063 L 455.484375 211.785156 Z M 458.9375 208.421875 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 452.03125 204.234375 L 453.835938 207.890625 L 453.921875 208.0625 L 454.113281 208.089844 L 458.148438 208.675781 L 455.226563 211.523438 L 455.089844 211.65625 L 455.121094 211.847656 L 455.8125 215.867188 L 452.203125 213.96875 L 452.03125 213.878906 L 451.859375 213.96875 L 448.25 215.867188 L 448.941406 211.847656 L 448.972656 211.65625 L 448.835938 211.523438 L 445.914063 208.675781 L 449.949219 208.089844 L 450.140625 208.0625 L 450.226563 207.890625 Z M 452.03125 203.402344 L 449.898438 207.726563 L 445.125 208.421875 L 448.578125 211.785156 L 447.765625 216.539063 L 452.03125 214.296875 L 456.300781 216.539063 L 455.484375 211.785156 L 458.9375 208.421875 L 454.164063 207.726563 Z M 452.03125 203.402344 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 468.742188 289.789063 L 463.96875 289.097656 L 461.835938 284.773438 L 459.703125 289.097656 L 454.929688 289.789063 L 458.382813 293.15625 L 457.570313 297.90625 L 461.835938 295.664063 L 466.105469 297.90625 L 465.289063 293.15625 Z M 468.742188 289.789063 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 461.835938 285.601563 L 463.640625 289.257813 L 463.726563 289.433594 L 463.917969 289.460938 L 467.953125 290.046875 L 465.03125 292.890625 L 464.894531 293.027344 L 464.925781 293.21875 L 465.617188 297.234375 L 462.007813 295.339844 L 461.835938 295.25 L 461.664063 295.339844 L 458.054688 297.234375 L 458.746094 293.21875 L 458.777344 293.027344 L 458.640625 292.890625 L 455.71875 290.046875 L 459.753906 289.460938 L 459.945313 289.433594 L 460.03125 289.257813 Z M 461.835938 284.773438 L 459.703125 289.097656 L 454.929688 289.789063 L 458.382813 293.15625 L 457.570313 297.90625 L 461.835938 295.664063 L 466.105469 297.90625 L 465.289063 293.15625 L 468.742188 289.789063 L 463.96875 289.097656 Z M 461.835938 284.773438 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 433.847656 208.421875 L 429.078125 207.726563 L 426.945313 203.402344 L 424.808594 207.726563 L 420.039063 208.421875 L 423.492188 211.785156 L 422.675781 216.539063 L 426.945313 214.292969 L 431.210938 216.539063 L 430.394531 211.785156 Z M 433.847656 208.421875 "/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 426.945313 204.234375 L 428.75 207.890625 L 428.835938 208.0625 L 429.027344 208.089844 L 433.058594 208.675781 L 430.140625 211.523438 L 430.003906 211.65625 L 430.035156 211.847656 L 430.722656 215.867188 L 427.117188 213.96875 L 426.945313 213.878906 L 426.773438 213.96875 L 423.164063 215.867188 L 423.851563 211.847656 L 423.886719 211.65625 L 423.75 211.523438 L 420.828125 208.675781 L 424.863281 208.089844 L 425.054688 208.0625 L 425.140625 207.890625 Z M 426.945313 203.402344 L 424.808594 207.726563 L 420.039063 208.421875 L 423.492188 211.785156 L 422.675781 216.539063 L 426.945313 214.296875 L 431.210938 216.539063 L 430.398438 211.785156 L 433.847656 208.421875 L 429.078125 207.726563 Z M 426.945313 203.402344 "/>
        <path style="fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:10;" d="M -0.00098125 -0.0008875 L -0.00098125 6.393644 L 1.749019 6.393644 C 1.815425 6.393644 1.842769 6.483487 1.788081 6.52255 L -4.465825 10.717862 L -10.704106 6.530362 C -10.766606 6.4913 -10.735356 6.393644 -10.661137 6.393644 L -8.934575 6.393644 L -8.934575 -0.0008875 L -10.934575 -0.0008875 L -10.934575 -2.149325 L 1.952144 -2.149325 L 1.952144 -0.0008875 Z M -2.149419 6.393644 L -0.340825 6.393644 L -0.340825 -0.0008875 L -2.149419 -0.0008875 Z M -4.6377 -0.0008875 L -6.442387 -0.0008875 L -6.442387 6.393644 L -4.6377 6.393644 Z M -2.489262 -0.0008875 L -4.297856 -0.0008875 L -4.297856 6.393644 L -2.489262 6.393644 Z M -9.782231 6.737394 L -4.465825 10.3038 L 0.850581 6.737394 Z M -8.590825 6.393644 L -6.786137 6.393644 L -6.786137 -0.0008875 L -8.590825 -0.0008875 Z M 1.6123 -1.805575 L -10.590825 -1.805575 L -10.590825 -0.340731 L 1.6123 -0.340731 Z M 1.6123 -1.805575 " transform="matrix(1,0,0,-1,663.0752,285.2413)"/>
        <path style="fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;stroke-width:0.05;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:10;" d="M -0.00098125 -0.0015 L -0.00098125 6.393031 L 1.749019 6.393031 C 1.815425 6.393031 1.842769 6.482875 1.788081 6.521937 L -4.465825 10.71725 L -10.704106 6.533656 C -10.766606 6.490687 -10.735356 6.393031 -10.661137 6.393031 L -8.934575 6.393031 L -8.934575 -0.0015 L -10.934575 -0.0015 L -10.934575 -2.149938 L 1.952144 -2.149938 L 1.952144 -0.0015 Z M -2.149419 6.393031 L -0.340825 6.393031 L -0.340825 -0.0015 L -2.149419 -0.0015 Z M -4.6377 -0.0015 L -6.442387 -0.0015 L -6.442387 6.393031 L -4.6377 6.393031 Z M -2.489262 -0.0015 L -4.297856 -0.0015 L -4.297856 6.393031 L -2.489262 6.393031 Z M -9.782231 6.736781 L -4.465825 10.303187 L 0.850581 6.736781 Z M -8.590825 6.393031 L -6.786137 6.393031 L -6.786137 -0.0015 L -8.590825 -0.0015 Z M 1.6123 -1.806188 L -10.590825 -1.806188 L -10.590825 -0.341344 L 1.6123 -0.341344 Z M 1.6123 -1.806188 " transform="matrix(1,0,0,-1,663.0752,353.311)"/>
        <path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 680.222656 317.453125 L 691.992188 324.246094 L 691.992188 310.65625 Z M 679.480469 317.453125 L 692.363281 310.015625 L 692.363281 324.890625 Z M 679.480469 317.453125 "/>
        <path style="fill:none;stroke-width:1.149;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 0.001625 0.00006875 L 12.130531 -0.0780563 " transform="matrix(1,0,0,-1,663.639,312.3321)"/>
        <path style="fill:none;stroke-width:1.149;stroke-linecap:butt;stroke-linejoin:miter;stroke:rgb(0%,0%,0%);stroke-opacity:1;stroke-miterlimit:4;" d="M 0.00170625 0.00080625 L 12.130612 -0.0773188 " transform="matrix(1,0,0,-1,663.6897,322.4969)"/>
      </g>
    </svg>
  </div>
</div>
