<ng-sidebar-container style="position: fixed; z-index: 999">
  <ng-sidebar [class.light-sidebar]="theme.currentTheme === 'light'"
              [class.dark-sidebar]="theme.currentTheme === 'dark'"
              class="px-2" [opened]="opened">
    <div style="height: 5vh"
    ></div>
    <div
      class="px-2 d-flex flex-column justify-content-center align-items-center"
    >
      <span
        [class.light-menu-title]="theme.currentTheme === 'light'"
        [class.dark-menu-title]="theme.currentTheme === 'dark'"
        style="
          font-family: HumanistBold, sans-serif;
          font-size: 101px;
        "
      >
        Меню
      </span>
      <div
        style="
          padding-top: 1vh;
          padding-bottom: 1vh;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          [class.light-bg-line]="theme.currentTheme === 'light'"
          [class.dark-bg-line]="theme.currentTheme === 'dark'"
          style="width: 100%; height: 15px"></div>
      </div>
      <div class="w-100 d-flex flex-column justify-content-between">
          <div style="height: 2vh"></div>
        <div
          style="
              justify-content: start;
              display: flex;
              align-items: center;
              gap: 2vw;
              padding-left: 120px;
            "
        >
          <app-icons [data]="'menu-3'"></app-icons>
          <div style="display: flex; flex-direction: column">
              <span
                (click)="opened = !opened"
                routerLink="/memorial-list"
                [class.light-menu-title]="theme.currentTheme === 'light'"
                [class.dark-menu-title]="theme.currentTheme === 'dark'"
                style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
              >
                История
              </span>
            <span
              (click)="opened = !opened"
              routerLink="/memorial-list"
              [class.light-menu-title]="theme.currentTheme === 'light'"
              [class.dark-menu-title]="theme.currentTheme === 'dark'"
              style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
            >
                мемориала
              </span>
          </div>
        </div>
        <div style="height: 2vh"></div>

        <div
            style="
              justify-content: start;
              padding-left: 120px;
              display: flex;
              align-items: center;
              gap: 2vw;
            "
          >
            <app-icons [data]="'menu-1'"></app-icons>
            <span
              (click)="opened = !opened"
              routerLink="/mapbox"
              [class.light-menu-title]="theme.currentTheme === 'light'"
              [class.dark-menu-title]="theme.currentTheme === 'dark'"

              style="
                font-family: HumanistRoman, sans-serif;
                font-size: 80px;
              "
            >
              Карта-схема мемориала и поиск захоронения по имени
            </span>
          </div>
        <div style="height: 2vh"></div>

        <div
          style="
              justify-content: start;
              display: flex;
              align-items: center;
              gap: 2vw;
              padding-left: 120px;
            "
        >
          <app-icons [data]="'menu-4'"></app-icons>
          <div style="display: flex; flex-direction: column">
              <span
                (click)="opened = !opened"
                routerLink="/map"
                [class.light-menu-title]="theme.currentTheme === 'light'"
                [class.dark-menu-title]="theme.currentTheme === 'dark'"
                style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
              >
                Картография со свободными местами захоронения
              </span>
          </div>
        </div>
        <div style="height: 2vh"></div>

        <div
          style="
              justify-content: start;
              display: flex;
              align-items: center;
              gap: 2vw;
              padding-left: 120px;
            "
        >
          <app-icons [data]="'menu-7'"></app-icons>
          <div style="display: flex; flex-direction: column">
              <span routerLink="/description"
                (click)="opened = !opened"
                [class.light-menu-title]="theme.currentTheme === 'light'"
                [class.dark-menu-title]="theme.currentTheme === 'dark'"
                style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
              >
                Описание захоронения
              </span>
          </div>
        </div>
          <div style="height: 2vh"></div>
          <div
            style="
              justify-content: start;
              padding-left: 120px;
              display: flex;
              align-items: center;
              gap: 2vw;
            "
          >
            <app-icons [data]="'menu-2'"></app-icons>

            <div style="display: flex; flex-direction: column">
              <span
                (click)="opened = !opened"
                routerLink="/excursive"
                [class.light-menu-title]="theme.currentTheme === 'light'"
                [class.dark-menu-title]="theme.currentTheme === 'dark'"
                style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
              >
                Экскурсионное
              </span>
              <span
                (click)="opened = !opened"
                routerLink="/excursive"
                [class.light-menu-title]="theme.currentTheme === 'light'"
                [class.dark-menu-title]="theme.currentTheme === 'dark'"
                style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
              >
                обслуживание
              </span>
            </div>
          </div>
          <div style="height: 2vh"></div>
          <div
            style="
              justify-content: start;
              display: flex;
              align-items: center;
              gap: 2vw;
              padding-left: 120px;
            "
          >
            <app-icons [data]="'menu-6'"></app-icons>
            <div style="display: flex; flex-direction: column">
              <span
                (click)="opened = !opened"
                routerLink="/info"
                [class.light-menu-title]="theme.currentTheme === 'light'"
                [class.dark-menu-title]="theme.currentTheme === 'dark'"
                style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
              >
                Порядок создания захоронения
              </span>
            </div>
          </div>
        <div style="height: 2vh"></div>
        <div
          style="
              justify-content: start;
              display: flex;
              align-items: center;
              gap: 2vw;
              padding-left: 120px;
            "
        >
          <app-icons [data]="'menu-5'"></app-icons>
          <div style="display: flex; flex-direction: column">
              <span
                (click)="opened = !opened"
                routerLink="/contacts"
                [class.light-menu-title]="theme.currentTheme === 'light'"
                [class.dark-menu-title]="theme.currentTheme === 'dark'"
                style="
                  font-family: HumanistRoman, sans-serif;
                  font-size: 80px;
                "
              >
                Режим работы и контакты
              </span>
          </div>
        </div>
        <div style="height: 2vh"></div>
        <div
          style="
              justify-content: start;
              padding-left: 120px;
              display: flex;
              align-items: center;
              gap: 2vw;
            "
        >
          <button
            class="sw-theme"
            [class.light-border]="theme.currentTheme === 'light'"
            [class.dark-border]="theme.currentTheme === 'dark'"
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"

            (click)="theme.switchTheme()"
          >{{theme.currentTheme === 'light' ? 'Светлая' : 'Темная'}} тема</button>
        </div>
      </div>
    </div>
  </ng-sidebar>
  <div ng-sidebar-content>
    <div [class.light-bg]="theme.currentTheme === 'light'" class="header-wrapper header-icons">
      <svg
        (click)="opened = !opened"
        *ngIf="opened"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 496.096 496.096"
        style="enable-background: new 0 0 496.096 496.096"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              d="M259.41,247.998L493.754,13.654c3.123-3.124,3.123-8.188,0-11.312c-3.124-3.123-8.188-3.123-11.312,0L248.098,236.686
			L13.754,2.342C10.576-0.727,5.512-0.639,2.442,2.539c-2.994,3.1-2.994,8.015,0,11.115l234.344,234.344L2.442,482.342
			c-3.178,3.07-3.266,8.134-0.196,11.312s8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L248.098,259.31
			l234.344,234.344c3.178,3.07,8.242,2.982,11.312-0.196c2.995-3.1,2.995-8.016,0-11.116L259.41,247.998z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>

      <svg
        *ngIf="!opened"
        (click)="opened = !opened"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="enable-background: new 0 0 512 512"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              d="M501.333,96H10.667C4.779,96,0,100.779,0,106.667s4.779,10.667,10.667,10.667h490.667c5.888,0,10.667-4.779,10.667-10.667
			S507.221,96,501.333,96z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M501.333,245.333H10.667C4.779,245.333,0,250.112,0,256s4.779,10.667,10.667,10.667h490.667
			c5.888,0,10.667-4.779,10.667-10.667S507.221,245.333,501.333,245.333z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M501.333,394.667H10.667C4.779,394.667,0,399.445,0,405.333C0,411.221,4.779,416,10.667,416h490.667
			c5.888,0,10.667-4.779,10.667-10.667C512,399.445,507.221,394.667,501.333,394.667z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>

      <svg
        *ngIf="search"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="enable-background: new 0 0 512 512"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              d="M508.875,493.792L353.089,338.005c32.358-35.927,52.245-83.296,52.245-135.339C405.333,90.917,314.417,0,202.667,0
			S0,90.917,0,202.667s90.917,202.667,202.667,202.667c52.043,0,99.411-19.887,135.339-52.245l155.786,155.786
			c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125C513.042,504.708,513.042,497.958,508.875,493.792z
			 M202.667,384c-99.979,0-181.333-81.344-181.333-181.333S102.688,21.333,202.667,21.333S384,102.677,384,202.667
			S302.646,384,202.667,384z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
    <div class="app-container" (click)="opened ? (opened = !opened) : null">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-sidebar-container>
