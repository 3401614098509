import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppComponent} from './app.component';
import {ExcursiveComponent} from './excursive/excursive.component';
import {MapComponent} from "./map/map.component";
import {MemorialListComponent} from "./memorial-list/memorial-list.component";
import {CurrentMemorialComponent} from "./current-memorial/current-memorial.component";
import {MainScreenComponent} from "./main-screen/main-screen.component";
import {ContactsComponent} from "./contacts/contacts.component";
import {MapboxMapComponent} from "./mapbox-map/mapbox-map.component";
import {InfoComponent} from './info/info.component';
import {DescriptionComponent} from "./description/description.component";

const routes: Routes = [
  { path: '', children: [
      { path: 'mapbox', component: MapboxMapComponent },
      { path: '', component: MainScreenComponent },
      { path: 'excursive', component: ExcursiveComponent },
      { path: 'map', component: MapComponent},
      { path: 'description', component: DescriptionComponent },
      { path: 'memorial-list', component: MemorialListComponent },
      { path: 'memorial/:id', component: CurrentMemorialComponent },
      { path: 'current-memorial', component: CurrentMemorialComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'info', component: InfoComponent }
    ] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
