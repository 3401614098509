import { Component, OnInit } from '@angular/core';
import {AppComponent} from "../app.component";
import {FunctionsService} from "../services/functions.service";
import {ThemeService} from '../shared/services/theme.service';

@Component({
  selector: 'app-main-screen',
  templateUrl: './main-screen.component.html',
  styleUrls: ['./main-screen.component.scss']
})
export class MainScreenComponent implements OnInit {

  constructor(
    public theme: ThemeService,
    public mainFunc: AppComponent,
    private func: FunctionsService) { }
  switchTrigger(e): void {
    e.preventDefault();
    this.func.switchMenu.next(true);
  }
  ngOnInit(): void {
  }

}
