<div class="w-100 h-100 d-flex align-items-start flex-column"
     [class.light-bg]="theme.currentTheme === 'light'"
     [class.dark-bg]="theme.currentTheme === 'dark'"
>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      Порядок создания захоронения
    </span>
  </div>
  <div style="width: 100%; margin-top: 1vh; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 10px"></div>
  </div>
  <div class="mar-x" style="padding-top: 1vh">
    <p class="m-0"
       [class.light-text]="theme.currentTheme === 'light'"
       [class.dark-text]="theme.currentTheme === 'dark'"
       style="font-family: HumanistRoman, sans-serif;
    font-weight: 500;
    font-size: 64px;">
      <b>Родовое семейное захоронение</b> - это участоко на кладбище, который любое физическое лицо (в том числе
      без московской прописки) может официально приобрести у Правительства Москвы для погребения себя и/или членов своей семьи
    </p>
  </div>
  <div style="margin-top: 1.5vh" class="w-100 d-flex align-items-start flex-column"
       [class.light-bg]="theme.currentTheme === 'light'"
       [class.dark-bg]="theme.currentTheme === 'dark'"
  >
    <div style="width: 100%; display: flex; justify-content: center; align-items: center">
      <div class="mar-x"
           [class.light-bg-line]="theme.currentTheme === 'light'"
           [class.dark-bg-line]="theme.currentTheme === 'dark'"
           style="width: 100%;height: 30px"></div>
    </div>

</div>
  <div class="mar-x" style="padding-top: 1vh">
    <p class="m-0"
       [class.light-text]="theme.currentTheme === 'light'"
       [class.dark-text]="theme.currentTheme === 'dark'"
       style="font-family: HumanistRoman, sans-serif;
    font-weight: 500;
    font-size: 64px;">
      <b>Приобрести право на такой участок можно двумя способами:</b>
    </p>
  </div>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 5px; margin-top: 1.5vh;"></div>
  </div>
  <div class="mar-x">
    <p class="m-0"
       [class.light-text]="theme.currentTheme === 'light'"
       [class.dark-text]="theme.currentTheme === 'dark'"
       style="font-family: HumanistRoman, sans-serif;
    font-weight: 500;
    font-size: 64px;">
      На открытом аукционе. Этот способ подходит тогда, когда вы <b>заранее</b> озаботились получением участка под
      будущее родовое захоронение
    </p>
  </div>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 5px; margin-top: 1.5vh;"></div>
  </div>
  <div class="mar-x">
    <p class="m-0"
       [class.light-text]="theme.currentTheme === 'light'"
       [class.dark-text]="theme.currentTheme === 'dark'"
       style="font-family: HumanistRoman, sans-serif;
    font-weight: 500;
    font-size: 64px;">
      Напрямую у правительства Москвы. Этим способом пользуются тогда,
      когда у родственников есть на руках свидетельство о смерти, похоронить негде и срочно нужна могила
    </p>
  </div>
  <div style="width: 100%; margin-top: 1.5vh; margin-bottom: 1.5vh; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x" style="padding-top: 1vh">
    <p class="m-0"
       [class.light-text]="theme.currentTheme === 'light'"
       [class.dark-text]="theme.currentTheme === 'dark'"
       style="font-family: HumanistRoman, sans-serif;
    font-weight: 500;
    font-size: 64px;">
      Участки под семейное захоронение в Москве предлагаются исключительно на закрытых кладбищах.
      <br><br>
      Резервирование мест под семейное захоронение происходит на сайте ЕАС Ритуал. Здесь можно ознакомиться
      с единым реестром выставленных на продажу участков, их описанием, текущими ценами и порядком торгов.
      <br><br>
      Площадь минимального участка в Москве для погребления одной урны составляет 1.1 х 0.8 и 1.8 х 2 для погребления
      гробом на две могилы. Максимальный размер установлен в 10 кв. м. Средний размер родового (семейного) захоронения при покупке в ходе
      торгов, составил на сентябрь 2016 года 6,24 кв. метров.
    </p>
  </div>
</div>
