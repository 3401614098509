import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../shared/services/theme.service';

@Component({
  selector: 'app-mapbox-map',
  templateUrl: './mapbox-map.component.html',
  styleUrls: ['./mapbox-map.component.scss']
})
export class MapboxMapComponent implements OnInit {
  mapInstance = null;
  constructor(public theme: ThemeService) { }
  onMapLoaded(event): void {
    event.resize();
    this.mapInstance = event;
  }
  ngOnInit(): void {
  }

}
