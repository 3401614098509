import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { ExcursiveComponent } from './excursive/excursive.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MapComponent } from './map/map.component';
import {SidebarModule} from "ng-sidebar";
import {FormsModule} from "@angular/forms";
import { MemorialListComponent } from './memorial-list/memorial-list.component';
import { CurrentMemorialComponent } from './current-memorial/current-memorial.component';
import { MainScreenComponent } from './main-screen/main-screen.component';
import { ContactsComponent } from './contacts/contacts.component';
import { KartografiaComponent } from './kartografia/kartografia.component';
import { MapboxMapComponent } from './mapbox-map/mapbox-map.component';
import {NgxMapboxGLModule} from "ngx-mapbox-gl";
import { IconsComponent } from './icons/icons.component';
import { InfoComponent } from './info/info.component';
import {HttpClientModule} from "@angular/common/http";
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { DescriptionComponent } from './description/description.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";

registerLocaleData(localeRu, 'ru');
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ExcursiveComponent,
    MapComponent,
    MemorialListComponent,
    CurrentMemorialComponent,
    MainScreenComponent,
    ContactsComponent,
    KartografiaComponent,
    MapboxMapComponent,
    IconsComponent,
    InfoComponent,
    DescriptionComponent
  ],
  imports: [
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoibWFmYWhlcyIsImEiOiJjazV6cW5xdDUwMDRrM21ueHF2Z3EzY3VyIn0.RRuRqnVCy3VWno0v3Xk__w',
      geocoderAccessToken: 'TOKEN'
    }),
    SidebarModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
