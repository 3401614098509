import { Component, OnInit } from '@angular/core';
import {ThemeService} from '../shared/services/theme.service';

@Component({
  selector: 'app-excursive',
  templateUrl: './excursive.component.html',
  styleUrls: ['./excursive.component.scss']
})
export class ExcursiveComponent implements OnInit {

  constructor(public theme: ThemeService) { }

  ngOnInit(): void {
  }

}
