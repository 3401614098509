import {Injectable, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themeSwitch: Subject<string> = new Subject<string>();
  currentTheme = 'dark';
  switchTheme(): void {
    if (this.currentTheme === 'dark') {
      this.currentTheme = 'light';
    } else {
      this.currentTheme = 'dark';
    }
  }
  constructor() { }
}
