import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ThemeService} from '../shared/services/theme.service';
import {ApiService} from "../shared/services/api.service";
import {MapPoint} from "../shared/interface/map";
import * as SvgPanZoom from 'svg-pan-zoom';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild('svgMap') refInput: ElementRef;
  @ViewChild('panspec') panInput: ElementRef;
  svgPanZoom: SvgPanZoom.Instance;
  constructor(public theme: ThemeService, private renderer: Renderer2, private api: ApiService) { }
  test = '270 250 350.394 266.929';
  searchText = '';
  selectedItem: MapPoint;
  data: MapPoint[] = [
  ];
  ngOnInit(): void {
    this.api.getMapPoints().subscribe((e) => {
      this.data = e;
    });
  }
  ngAfterViewInit(): void {
    this.svgPanZoom = SvgPanZoom('#svgPanWrap', {
      viewportSelector: '.svg-pan-zoom_viewport'
      , panEnabled: true
      , controlIconsEnabled: false
      , zoomEnabled: true
      , dblClickZoomEnabled: true
      , mouseWheelZoomEnabled: true
      , preventMouseEventsDefault: true
      , zoomScaleSensitivity: 0.2
      , minZoom: 0.5
      , maxZoom: 10
      , fit: true
      , contain: false
      , center: true
      , refreshRate: 'auto'
      // , beforeZoom: function() {}
      // , onZoom: function() {}
      // , beforePan: function() {}
      // , onPan: function() {}
      // , onUpdatedCTM: function() {}
      , eventsListenerElement: null
    });
  }
  onSearch(event): void {
    console.log(event);
  }
  onSelect(item: MapPoint): void {
    this.renderer.setStyle(this.panInput.nativeElement, 'transform', item.viewBox);
    this.searchText = '';
    this.selectedItem = item;
  }
}
