<div class="w-100 h-100 d-flex align-items-start flex-column"
     [class.light-bg]="theme.currentTheme === 'light'"
     [class.dark-bg]="theme.currentTheme === 'dark'">
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div
      [class.light-bg-line]="theme.currentTheme === 'light'"
      [class.dark-bg-line]="theme.currentTheme === 'dark'"
      class="mar-x" style="width: 100%;height: 30px"></div>
  </div>
  <div class="mar-x">
    <span
      [class.light-text]="theme.currentTheme === 'light'"
      [class.dark-text]="theme.currentTheme === 'dark'"
      style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 101px">
      Знаменитые мемориалы
    </span>
  </div>
  <div style="width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="mar-x"
         [class.light-bg-line]="theme.currentTheme === 'light'"
         [class.dark-bg-line]="theme.currentTheme === 'dark'"
         style="width: 100%;height: 10px"></div>
  </div>
  <div class="mar-x" style="height: 100%; width: calc(100% - 20vw)">
    <div class="container-fluid my-5" *ngFor="let item of memorials" [routerLink]="'/memorial/' + item.id">
      <div class="row">
        <div class="col-2">
          <div style="border-radius: 40px; width: 230px; height: 230px; background-size: cover;" [style.background-image]="'url(' + (item.photo === null ? 'assets/images/1.png' : item.photo.fullUrl) + ')'">

          </div>
        </div>
        <div class="col-10 justify-content-center d-flex flex-column">
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistBold, sans-serif; font-weight: bold; font-size: 74px">{{item.name}}</span>
          <span
            [class.light-text]="theme.currentTheme === 'light'"
            [class.dark-text]="theme.currentTheme === 'dark'"
            style="font-family: HumanistRoman, sans-serif;font-size: 57px;">{{item.date | date: 'dd MMMM yyyy'}} - {{item.date2 | date: 'dd MMMM yyyy'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
